import { debounce } from '@material-ui/core';
import { LoadScript } from '@react-google-maps/api';
import cx from 'classnames';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useConfig } from '@core/contexts/ConfigContext';
import { breakpointValues } from '@core/theme/units/breakpoints';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { layoutActions } from '@redux/reducers/layout/layoutReducer';
import { layoutSelectors } from '@redux/selectors/layout';
import { Loading } from '@shared/components/loading';
import { SideNavigation } from '@shared/components/side-navigation';

import { styles } from './Layout.styles';

export type LayoutProps = WithStyles<typeof styles>;

const calculateNavMenuCollapsed = (previousValue: boolean) => {
  if (window.innerWidth < breakpointValues.md) {
    return true;
  }

  if (window.innerWidth >= breakpointValues.md && previousValue) {
    return false;
  }

  return previousValue;
};

const LayoutComponent: FC<LayoutProps> = ({ classes, children }) => {
  const dispatch = useDispatch();

  const { constants } = useConfig();
  const navMenuCollapsed = useSelector(layoutSelectors.getNavMenuStatus);

  useEffect(() => {
    const handleResize = () => {
      dispatch(layoutActions.toggleCollapsedNavMenu(calculateNavMenuCollapsed(navMenuCollapsed)));
    };

    window.addEventListener('resize', debounce(handleResize, 50));

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <LoadScript
      id="google-map-script"
      googleMapsApiKey={constants.GOOGLE_MAPS_HTTP_API}
      loadingElement={<Loading absolute />}
    >
      <SideNavigation
        collapsed={navMenuCollapsed}
        classes={{
          root: classes.sideBar,
          rootCollapsed: classes.sideNavigationCollapsed,
        }}
        onCollapse={() => dispatch(layoutActions.toggleCollapsedNavMenu(!navMenuCollapsed))}
      />
      <div className={cx(classes.app, { [classes.appCollapsed]: navMenuCollapsed })}>{children}</div>
    </LoadScript>
  );
};

export const Layout = withStyles(styles)(LayoutComponent);
