import { BreakpointValues } from '@material-ui/core/styles/createBreakpoints';

import { BreakpointsOptions } from '@core/theme/types/main';

export const breakpointValues: BreakpointValues = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export const initializeBreakpoints = (config?: BreakpointsOptions): BreakpointsOptions => ({
  ...config,
  values: breakpointValues,
});
