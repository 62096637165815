import { useEffect, useMemo, useState } from 'react';

import { getQueries, setQueries } from '@shared/utils/common';

interface UseOverlaysOptions<T> {
  blacklist?: (keyof T)[];
}

export type UseOverlaysRecord<T extends Item<string | boolean> = Item<string | boolean>> = T;

export const useOverlays = <T extends UseOverlaysRecord = UseOverlaysRecord>(
  initialOverlays: T,
  opts?: UseOverlaysOptions<T>
) => {
  const [overlays, setOverlays] = useState<T>(initialOverlays);

  useEffect(() => {
    const newQueries = { ...getQueries() };
    // console.log('useOverlays. queries before: ', { ...newQueries });

    for (const [key, overlay] of Object.entries(overlays)) {
      if (opts?.blacklist?.includes(key)) {
        continue;
      } else if (overlay) {
        newQueries[key] = overlay.toString();
      } else {
        delete newQueries[key];
      }
    }

    // console.log('useOverlays. set queries: ', { ...newQueries });
    setQueries(newQueries);
  }, [overlays]);

  return useMemo(() => ({ overlays, setOverlays }), [overlays]);
};
