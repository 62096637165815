import {
  createTheme,
  makeStyles as muiMakeStyles,
  StyleRulesCallback,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core/styles';
import { ClassNameMap, CSSProperties, StyleRules } from '@material-ui/styles';
import { useMemo } from 'react';

export { withStyles };
export type { WithStyles };

export type ClassesProp<T extends (...args: any) => any> = Partial<ReturnType<T>>;

export const getStyles = <T extends CSSProperties>(styles: T) =>
  styles as StyleRules<EmptyObject, Extract<keyof T, string>>;

type MakeClasses<T extends string = string> = (props?: any) => ClassNameMap<T>;

// Wrapper around MUI's makeStyles
export const makeStyles = <TKey extends string = string>(
  name: string,
  stylesFn: StyleRulesCallback<Theme, EmptyObject, TKey>
): MakeClasses<TKey> => muiMakeStyles<Theme, EmptyObject, TKey>(stylesFn, { name: name || 'UnknownComponent' });

export const getUseClasses =
  <TKey extends string = string>(makeClasses: MakeClasses<TKey>) =>
  (overrides?: Partial<ClassNameMap<TKey>>): ClassNameMap<TKey> => {
    const classes = makeClasses();

    return useMemo(() => {
      if (!overrides || !Object.keys(overrides).length) {
        // console.log('getUseClasses, return original classes', { ...classes });
        return classes;
      }

      const result = { ...classes };

      (Object.getOwnPropertyNames(overrides) as TKey[]).forEach((key: TKey) => {
        result[key] += ` ${overrides[key]}`;
      });

      // console.log('getUseClasses, return overrided classes', { ...result });
      return result;
    }, [classes, overrides]);
  };

export const mockTheme = createTheme();
